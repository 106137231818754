import Vue from 'vue'
import Router from 'vue-router'

// Containers
const TheContainer = () => import('@/containers/TheContainer')

const Login = () => import('@/views/pages/Login')

const SelectPersonDailyReport = () => import('@/views/SelectPerson/SelectPersonDailyReport/SelectPersonDailyReport');
const SelectPersonDoctorDirection = () => import('@/views/SelectPerson/SelectPersonDoctorDirection/SelectPersonDoctorDirection');
const SelectPersonHomeNursingPlan = () => import('@/views/SelectPerson/SelectPersonHomeNursingPlan/SelectPersonHomeNursingPlan');
const SelectPersonHomeNursingReport = () => import('@/views/SelectPerson/SelectPersonHomeNursingReport/SelectPersonHomeNursingReport');
const SelectPersonMedicalCooperationNursingReport = () => import('@/views/SelectPerson/SelectPersonMedicalCooperationNursingReport/SelectPersonMedicalCooperationNursingReport');

const DailyReports = () => import('@/views/DailyReports/DailyReports');
const DailyReportsChildren = () => import('@/views/DailyReportsChildren/DailyReportsChildren');
const DoctorDirection = () => import('@/views/DoctorDirection/DoctorDirection');
const HomeNursingPlan = () => import('@/views/HomeNursingPlan/HomeNursingPlan');
const MedicalCooperationNursingReports = () => import('@/views/MedicalCooperationNursingReports/MedicalCooperationNursingReports');
const MedicalCooperationNursingReportsChildren = () => import('@/views/MedicalCooperationNursingReportsChildren/MedicalCooperationNursingReportsChildren');
const HomeNursingReports = () => import('@/views/HomeNursingReports/HomeNursingReports');

const DisplayDoctorDirection = () => import('@/components/DoctorDirection/DisplayDoctorDirection/DisplayDoctorDirection');
const DisplayHomeNursingPlan = () => import('@/components/HomeNursingPlan/DisplayHomeNursingPlan/DisplayHomeNursingPlan');
const DisplayHomeNursingReport = () => import('@/components/HomeNursingReports/DisplayHomeNursingReport/DisplayHomeNursingReport');
const DisplayMedicalCooperationNursingReport = () => import('@/components/MedicalCooperationNursingReports/DisplayMedicalCooperationNursingReport/DisplayMedicalCooperationNursingReport');
const DisplayMedicalCooperationNursingReportChildren = () => import('@/components/MedicalCooperationNursingReportsChildren/DisplayMedicalCooperationNursingReportChildren/DisplayMedicalCooperationNursingReportChildren');


const Users = () => import('@/views/users/Users')
const Facilities = () => import('@/views/Facilities/Facilities');
const Offices = () => import('@/views/Offices/Offices');
const Persons = () => import('@/views/Persons/Persons');


Vue.use(Router)

export default new Router({
  mode: 'history',
  linkActiveClass: 'active',
  scrollBehavior: () => ({ y: 0 }),
  routes: configRoutes()
})

function configRoutes() {
    return [
        {
            path: '/login',
            name: 'Login',
            component: Login,
        },
        {
            path: '/',
            redirect: '/daily-report-select',
            name: 'TheContainer',
            component: TheContainer,
            children: [
                {
                    path: 'daily-report-select',
                    name: 'SelectPersonDailyReport',
                    component: SelectPersonDailyReport
                },
                {
                    path: 'doctor-direction-select',
                    name: 'SelectPersonDoctorDirection',
                    component: SelectPersonDoctorDirection
                },
                {
                    path: 'home-nursing-plan-select',
                    name: 'SelectPersonHomeNursingPlan',
                    component: SelectPersonHomeNursingPlan
                },
                {
                    path: 'home-nursing-report-select',
                    name: 'SelectPersonHomeNursingReport',
                    component: SelectPersonHomeNursingReport
                },
                {
                    path: 'medical-cooperation-nursing-report-select',
                    name: 'SelectPersonMedicalCooperationNursingReport',
                    component: SelectPersonMedicalCooperationNursingReport
                },
                // {
                //   path: 'dashboard',
                //   name: 'Dashboard',
                //   component: Dashboard
                // },
                {
                    path: 'daily-reports/:id',
                    name: 'DailyReports',
                    component: DailyReports
                },
                {
                    path: 'daily-reports-children/:id',
                    name: 'DailyReportsChildren',
                    component: DailyReportsChildren
                },
                {
                    path: 'doctor-direction/:id',
                    name: 'DoctorDirection',
                    component: DoctorDirection
                },
                {
                    path: 'display-doctor-direction/:id',
                    name: 'DisplayDoctorDirection',
                    component: DisplayDoctorDirection
                },
                {
                    path: 'home-nursing-plan/:id',
                    name: 'HomeNursingPlan',
                    component: HomeNursingPlan
                },
                {
                    path: 'display-home-nursing-plan/:id',
                    name: 'DisplayHomeNursingPlan',
                    component: DisplayHomeNursingPlan
                },
                {
                    path: 'medical-cooperation-nursing-reports/:id',
                    name: 'MedicalCooperationNursingReports',
                    component: MedicalCooperationNursingReports
                },
                {
                    path: 'display-medical-cooperation-nursing-report/:id',
                    name: 'DisplayMedicalCooperationNursingReport',
                    component: DisplayMedicalCooperationNursingReport
                },
                {
                    path: 'medical-cooperation-nursing-reports-children/:id',
                    name: 'MedicalCooperationNursingReportsChildren',
                    component: MedicalCooperationNursingReportsChildren
                },
                {
                    path: 'display-medical-cooperation-nursing-report-children/:id',
                    name: 'DisplayMedicalCooperationNursingReportChildren',
                    component: DisplayMedicalCooperationNursingReportChildren
                },
                {
                    path: 'display-home-nursing-report/:id',
                    name: 'DisplayHomeNursingReport',
                    component: DisplayHomeNursingReport
                },
                {
                    path: 'home-nursing-reports/:id',
                    name: 'HomeNursingReports',
                    component: HomeNursingReports
                },
                {
                    path: 'users',
                    name: 'Users',
                    component: Users
                },
                {
                    path: 'offices',
                    name: 'Offices',
                    component: Offices
                },
                {
                    path: 'facilities',
                    name: 'Facilities',
                    component: Facilities
                },
                {
                    path: 'persons',
                    name: 'Persons',
                    component: Persons
                },
            ]
        },
    ]
}

